var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nav nav-tabs mt-4 mb-4" },
    _vm._l(_vm.tabs, function(tab) {
      return _c(
        "li",
        { key: tab.name, staticClass: "nav-item" },
        [
          _c(
            "router-link",
            {
              staticClass: "nav-link",
              class: { active: tab.active },
              attrs: { to: { name: tab.link } }
            },
            [_vm._v("\n            " + _vm._s(tab.name) + "\n        ")]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }