var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "covid-map mt-3 mb-3" },
    [
      _c("h1", { staticClass: "text-center" }, [
        _vm._v("\n        Aristotle COVID-19 Dashboard\n    ")
      ]),
      _vm._v(" "),
      _c("tabs", { attrs: { tabs: _vm.tabs } }),
      _vm._v(" "),
      _c("covid-header-text"),
      _vm._v(" "),
      _c("error-group", { attrs: { errors: _vm.errors } }),
      _vm._v(" "),
      _vm.loading
        ? _c("loading")
        : [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-8" }, [
                _c("div", { staticClass: "graph-title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.graphTitle) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "map-graph-description" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.currentDataElementDefinition) +
                      "\n                "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-8 col-12" },
                [
                  _c("map-display", {
                    attrs: {
                      "map-data": _vm.mapData,
                      "color-axis-max-value": _vm.colorAxisMaxValue,
                      region: _vm.selectedRegion
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", [_vm._v("Date")]),
                    _vm._v(
                      ": " + _vm._s(_vm.formattedDate) + "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("div", { staticClass: "btn-group-justified" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm",
                            class: {
                              "btn-outline-info": !_vm.datesPlaying,
                              "btn-outline-success": _vm.datesPlaying
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                _vm.isLastDay
                                  ? _vm.restartAndPlayMapDates()
                                  : _vm.playMapDates()
                              }
                            }
                          },
                          [
                            !_vm.datesPlaying
                              ? _c("i", { staticClass: "fas fa-play" })
                              : _c("i", { staticClass: "fas fa-pause" })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm",
                            class: {
                              "btn-outline-success": !_vm.restartedAndPlaying,
                              "disabled btn-outline-secondary": _vm.playing
                            },
                            attrs: { type: "button", disabled: _vm.playing },
                            on: { click: _vm.restartAndPlayMapDates }
                          },
                          [_c("i", { staticClass: "fas fa-redo-alt" })]
                        )
                      ]),
                      _vm._v(" "),
                      _c("vue-slider", {
                        staticClass: "flex-grow-1 align-self-center pl-1",
                        attrs: { data: _vm.datesData },
                        model: {
                          value: _vm.sliderDateValue,
                          callback: function($$v) {
                            _vm.sliderDateValue = $$v
                          },
                          expression: "sliderDateValue"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4 col-12" }, [
                _c(
                  "div",
                  { staticClass: "card bg-light option-selector" },
                  [
                    _c("radio-selector", {
                      attrs: {
                        description: "Choose a data element",
                        options: _vm.options
                      },
                      model: {
                        value: _vm.selectedCategory,
                        callback: function($$v) {
                          _vm.selectedCategory = $$v
                        },
                        expression: "selectedCategory"
                      }
                    }),
                    _vm._v(" "),
                    _c("radio-selector", {
                      attrs: {
                        description: "Region Identifier",
                        "description-id": _vm.regionIdentifierId,
                        options: _vm.regionOptions,
                        "tooltip-for-options": false
                      },
                      model: {
                        value: _vm.selectedRegion,
                        callback: function($$v) {
                          _vm.selectedRegion = $$v
                        },
                        expression: "selectedRegion"
                      }
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.checkboxSections, function(checkboxSection) {
                      return _c(
                        "div",
                        { key: checkboxSection.id },
                        [
                          _c("checkbox-section", {
                            attrs: {
                              name: checkboxSection.name,
                              id: checkboxSection.id,
                              options: checkboxSection.valuemeaningSet
                            },
                            on: { updateCheckedOpt: _vm.updateCheckedOptions }
                          })
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ])
            ]),
            _vm._v(" "),
            _c("covid-metadata-display", {
              attrs: {
                id: "metadatadisplay",
                selected: _vm.allSelected,
                dss: _vm.dss
              }
            })
          ],
      _vm._v(" "),
      _c("about-this-display")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }