var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "form-block" },
    [
      _c(
        "div",
        {
          staticClass: "font-weight-bold",
          attrs: { "data-aristotle-concept-id": _vm.descriptionId }
        },
        [_vm._v("\n        " + _vm._s(_vm.description) + "\n    ")]
      ),
      _vm._v(" "),
      _vm.help
        ? _c("div", { staticClass: "help" }, [
            _vm._v("\n        " + _vm._s(_vm.help) + "\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.options, function(o) {
        return _c(
          "div",
          {
            key: o.value,
            ref: "option",
            refInFor: true,
            staticClass: "form-check",
            attrs: { "data-aristotle-concept-id": o.aristotleTooltipId }
          },
          [
            _c("input", {
              staticClass: "form-check-input",
              attrs: { type: "radio", id: o.value },
              domProps: { value: o.value, checked: o.value === _vm.value },
              on: { change: _vm.emitInput }
            }),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "form-check-label", attrs: { for: o.value } },
              [_vm._v(_vm._s(o.text))]
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }