var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "svg",
      {
        ref: "svg",
        staticClass: "metadata-display",
        attrs: {
          xmlns: _vm.svg_ns,
          width: "100%",
          preserveAspectRatio: "xMidYMid meet"
        }
      },
      [
        _c("g", { ref: "inner" }),
        _vm._v(" "),
        _c(
          "g",
          { ref: "headings", staticClass: "headings" },
          _vm._l(_vm.headings, function(h) {
            return _c(
              "text",
              { key: h.id, attrs: { id: h.id, x: h.x, y: h.y } },
              [_vm._v(_vm._s(h.text))]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }