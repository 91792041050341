var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "card-deck mb-3" }, [
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "card-img-top",
            attrs: { src: require("@/assets/landing-covid-bar.png"), alt: "" }
          }),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-footer" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary",
                  attrs: { to: { name: "covidGraph" } }
                },
                [
                  _vm._v(
                    "\n                        See the COVID Graph »\n                    "
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "card-img-top",
            attrs: { src: require("@/assets/landing-covid-map.png"), alt: "" }
          }),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-footer" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary",
                  attrs: { to: { name: "covidMap" } }
                },
                [
                  _vm._v(
                    "\n                        See the COVID Map »\n                    "
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-deck mb-3" }, [
        _c("div", { staticClass: "card" }, [
          _c("img", {
            staticClass: "card-img-top",
            attrs: { src: require("@/assets/sddf_excel.png"), alt: "" }
          }),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-footer" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary",
                  attrs: { to: { name: "sddf" } }
                },
                [
                  _vm._v(
                    "\n                        See the SDDF Documentation »\n                    "
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5)
      ])
    ]),
    _vm._v(" "),
    _c("hr", { staticClass: "m-5" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "jumbotron bg-light" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "display-4" }, [
          _c("i", { staticClass: "fas fa-flask" }),
          _vm._v("\n                Aristotle Labs\n            ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "lead" }, [
          _vm._v(
            "\n                Metadata isn't just about describing data, it's about "
          ),
          _c("em", [_vm._v("empowering data")]),
          _vm._v(
            "\n                to unlock its full potential.\n            "
          )
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "m-4" }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                Below are a few of the projects built on the "
          ),
          _c("a", { attrs: { href: "https://aristotlemetadata.com" } }, [
            _vm._v("Aristotle\n                Metadata Registry")
          ]),
          _vm._v(
            "\n                that demonstrate how a metadata registry makes data make sense.\n            "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "lead" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-outline-dark",
              attrs: { href: "https://aristotlemetadata.com", role: "button" }
            },
            [
              _vm._v(
                "\n                    Learn more about the Aristotle Metadata Registry »\n                "
              )
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body" }, [
      _c("h5", { staticClass: "card-title" }, [
        _vm._v(
          "\n                        COVID-19 Bar chart\n                    "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "card-text" }, [
        _vm._v(
          "\n                    View data from the European Center for Disease Control and the World Health Organisation,\n                        use metadata selections to examine a bar chart of COVID-19 Data. \n                        There is also a metadata display to discover exactly how the data was collected and collated.\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body" }, [
      _c("h5", { staticClass: "card-title" }, [
        _vm._v(
          "\n                        COVID-19 Map chart\n                    "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "card-text" }, [
        _vm._v(
          "\n                    View an interactive map based on COVID-19 data from the European Center for Disease Control and the World Health Organisation.\n                        This dashboard includes Aristotle Metadata that provides additional context to data and an interactive time slider to view the progression of the pandemic across the globe.\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body" }, [
      _c("h5", { staticClass: "card-title" }, [
        _vm._v(
          "\n                        Simple Data Dictionary Format Importer\n                    "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "card-text" }, [
        _vm._v(
          "\n                        View the Documentation for the Aristotle Simple Data Dictionary Importer.\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("img", {
        staticClass: "card-img-top",
        attrs: { src: require("@/assets/cropped_3d_graph.png") }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("h5", { staticClass: "card-title" }, [
          _vm._v("\n                        3D Graph Lab\n                    ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "card-text" }, [
          _vm._v(
            "\n                        View a 3D graph of an Aristotle Data Set Specification\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-footer" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            attrs: { href: "https://aristotle3d.netlify.app/" }
          },
          [
            _vm._v(
              "\n                        See the 3D graph »\n                    "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("img", {
        staticClass: "card-img-top",
        attrs: { src: require("@/assets/review_tool_screenshot.png") }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("h5", { staticClass: "card-title" }, [
          _vm._v(
            "\n                        Aristotle Metadata Advanced Review tool\n                    "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "card-text" }, [
          _vm._v(
            "\n                        Use the API-driven beta of our Advanced Review tool\n                        to perform quality control of your content.\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-footer" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            attrs: { href: "https://aristotle-pro-reviews.netlify.app/" }
          },
          [
            _vm._v(
              "\n                        Load the Advanced Review tool »\n                    "
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }