var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "covid-graph mt-3 mb-3" },
    [
      _c("h1", { staticClass: "text-center" }, [
        _vm._v("\n        Aristotle COVID-19 Dashboard\n    ")
      ]),
      _vm._v(" "),
      _c("tabs", { attrs: { tabs: _vm.tabs } }),
      _vm._v(" "),
      _c("covid-header-text"),
      _vm._v(" "),
      _vm.loading
        ? _c("loading")
        : [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-8" }, [
                _c("div", { staticClass: "graph-title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.graphTitle) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "graph-description" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.currentDataElementDefinition) +
                      "\n                "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-8" },
                [
                  _c("bar-graph", {
                    attrs: {
                      selected: _vm.allSelected,
                      raw_data: _vm.raw_data,
                      distribution_map: _vm.distributionDataMap,
                      data_element_options: _vm.options
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "card bg-light option-selector" },
                  [
                    _c("radio-selector", {
                      attrs: {
                        description: "Select data to display",
                        options: _vm.options
                      },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    }),
                    _vm._v(" "),
                    _c("radio-selector", {
                      attrs: {
                        description: "Choose a category data element",
                        options: _vm.categoryOptions
                      },
                      model: {
                        value: _vm.selectedCategory,
                        callback: function($$v) {
                          _vm.selectedCategory = $$v
                        },
                        expression: "selectedCategory"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("covid-metadata-display", {
              attrs: {
                id: "metadatadisplay",
                selected: _vm.allSelected,
                dss: _vm.dss
              }
            })
          ],
      _vm._v(" "),
      _c("about-this-display")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }