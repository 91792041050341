var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer footer-custom" }, [
      _c("div", { staticClass: "container text-center text-white" }, [
        _c("div", { staticClass: "promo" }, [
          _c("div", [
            _c("p", [
              _c(
                "a",
                {
                  staticClass: "text-white",
                  attrs: {
                    href:
                      "https://github.com/Aristotle-Metadata-Enterprises/aristotle-labs"
                  }
                },
                [
                  _c("i", { staticClass: "fab fa-github" }),
                  _vm._v(
                    "\n                        Aristotle Labs\n                    "
                  )
                ]
              ),
              _vm._v(
                "\n                        - powered by the Aristotle Metadata Registry\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("© 2020 Copyright:\n                    "),
              _c(
                "a",
                {
                  staticClass: "text-white",
                  attrs: { href: "https://aristotlemetadata.com/" }
                },
                [_vm._v("Aristotle Metadata Enterprises")]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }