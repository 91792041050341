var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("hr")
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", [
          _vm._v(
            "\n            This dashboard provides an interactive display of data about the 2020 "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://registry.aristotlemetadata.com/item/604099/",
                "data-aristotle-concept-id": "604099"
              }
            },
            [_vm._v("COVID-19")]
          ),
          _vm._v(
            " pandemic using data published by the European Centre for Disease Control. This data has been enhanced with metadata from an "
          ),
          _c("a", { attrs: { href: "https://aristotlemetadata.com" } }, [
            _vm._v("Aristotle Metadata Registry")
          ]),
          _vm._v(". Hover over the dashboard controls or any "),
          _c(
            "a",
            {
              attrs: {
                href: "https://registry.aristotlemetadata.com/item/604099/",
                "data-aristotle-concept-id": "604099"
              }
            },
            [_vm._v("underlined text")]
          ),
          _vm._v(
            " to see more information about data, classifications or glossary definitions. Scroll down to read more about how this graph was generated.\n            "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }