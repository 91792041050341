var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navbar navbar-custom navbar-expand-lg" },
    [
      _c(
        "router-link",
        { staticClass: "navbar-brand", attrs: { to: { name: "homePage" } } },
        [
          _c("img", {
            staticClass: "img-fluid aristotle-logo",
            attrs: {
              src: require("@/assets/aris_logo_small.png"),
              alt: "Aristotle Logo"
            }
          }),
          _vm._v("\n        Aristotle Labs\n    ")
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "collapse navbar-collapse",
          attrs: { id: "navbarSupportedContent" }
        },
        [
          _c("ul", { staticClass: "navbar-nav mr-auto" }, [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: { name: "homePage" } }
                  },
                  [_vm._v("\n                    Home\n                ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link dropdown-toggle",
                  attrs: {
                    href: "#",
                    id: "navbarDropdown",
                    role: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  }
                },
                [_vm._v("\n                    Labs\n                ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "navbarDropdown" }
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: { name: "covidGraph" } }
                    },
                    [
                      _vm._v(
                        "\n                        Covid Graph\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: { name: "covidMap" } }
                    },
                    [
                      _vm._v(
                        "\n                        Covid Map\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: { name: "sddf" } }
                    },
                    [
                      _vm._v(
                        "\n                        Simple Data Dictionary Format\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-outline-light",
          attrs: { href: "https://aristotlemetadata.com" }
        },
        [_vm._v("\n        Back to main site\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }