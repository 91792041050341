var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "mt-3 mb-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h1", { staticClass: "text-center" }, [
              _vm._v(
                "\n                    Aristotle Simple Data Dictionary Format "
              ),
              _c("i", { staticClass: "fas fa-file-excel" }),
              _vm._v(" "),
              _c("i", { staticClass: "fas fa-file-csv" })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [_c("h2", [_vm._v("Demo")])]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _vm._v("\n                Jump straight in an try a "),
            _c(
              "a",
              { attrs: { href: "https://aristotle.cloud/toolbox/sddf" } },
              [
                _vm._v(
                  "demo of the Simple Data Dictionary Importer at Aristotle.Cloud"
                )
              ]
            ),
            _vm._v(".\n            ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", [
              _vm._v("\n                    Summary\n                ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c("p", [
              _vm._v(
                "\n                    The Simple Data Dictionary Format is a simple way of recording a "
              ),
              _c("em", [_vm._v("data dictionary")]),
              _vm._v(
                " for a spreadsheet, data table or data asset.\n                "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("p", { staticClass: "col-md-6" }, [
                _vm._v("\n                        A "),
                _c("em", [_vm._v("data dictionary")]),
                _vm._v(
                  " is a document that records field names from data, along with definition of key\n                        concepts such as who data was collected from, how the data was stored and what type of data is\n                        stored for a given field. By recording a data dictionary in a consistent, simple and structured\n                        format it is easier to interpret data accurately.\n                        To provide consistency between data dictionaries, this document use terminology from\n                        ISO 11179, a widely recognised standard for metadata registries, for describing fields within a\n                        data dictionary. By using existing metadata standards this format makes it easy to build and share\n                        data dictionaries and import them into ISO 11179 compliant metadata registries, like the "
                ),
                _c(
                  "a",
                  { attrs: { href: "https://www.aristotlemetadata.com" } },
                  [_vm._v("Aristotle Metadata Registry")]
                ),
                _vm._v(".\n                    ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("iframe", {
                  attrs: {
                    width: "560",
                    height: "315",
                    src: "https://www.youtube.com/embed/aOVN0v-HWcQ",
                    frameborder: "0",
                    allow:
                      "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                    allowfullscreen: ""
                  }
                })
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("\n            File Structure\n        ")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n            Each SDDF file describes a single "),
          _c("em", [_vm._v("data asset")]),
          _vm._v(
            ", such as a single CSV file or by a sheet within an Excel document, or single table in a database.\n            This table will begin with a header that defines the fields used in the dictionary.\n            These fields are given consistent names selected from those described within this document.\n            Each row within the table maps to one single column or field within the data asset, as well as a single data element within the Data Dictionary.\n        "
          )
        ]),
        _c("p", [
          _vm._v(
            "\n            Where each row maps to a specific field from a data asset a “Column name” field should be included to record the name of that field.\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pt-4" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h3", [
              _vm._v(
                "\n                    ISO 11179 Metadata types\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10 offset-1 pt-3" }, [
            _c(
              "table",
              { staticClass: "table table-striped table-sm table-bordered" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n                            Metadata Type\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n                            Description\n                        "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Data Element\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            A precise way of defining how a measurable attribute of a set of objects may be recorded in a system, using reusable metadata components.\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Data Element Concept\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            A valid pairing of an Object Class and a Property to define a measurable attribute of the object, idea or event being observed.\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Object Class\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            A definition for classifying a set of real objects, ideas or events that all share common measurable attributes.\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Property\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            A definition of an attribute common to all members of a set defined by an Object Class.\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Value Domain\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            A definition of how to record the measurement a particular attribute, either using a coded list of values or a description of the possible values.\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Data Type\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            A description of the range of values that can be used to record information, as well as any valid operations that can be performed on the data.\n                        "
                      )
                    ])
                  ])
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 text-center" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/metadata_relationships.png"),
                alt: "metadata_relationships"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 text-center" }, [
            _vm._v(
              "\n                Figure 1: Relationship between ISO 11179 metadata type\n            "
            )
          ])
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("\n            Field names\n        ")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Fields are defined within the header of each column within the data dictionary. The mapping of a value to\n            the metadata registry field name is done by stating the metadata type and the field name, for example “"
          ),
          _c("b", [_vm._v("Data\n            Element")]),
          _vm._v(" "),
          _c("i", [_vm._v("Definition")]),
          _vm._v(
            "” where the bolded text shows the metadata type (“Data Element”) and the italic text shows\n            the field (“Definition”).\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h4", [
              _vm._v("\n                    Common fields\n                ")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                    Common fields are those that can be recorded for any metadata type:\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "offset-1 col-sm-2" }, [
                _vm._v(
                  "\n                        Definition\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v(
                  "\n                        The definition of the metadata item within this data dictionary.\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dt", { staticClass: "offset-1 col-sm-2" }, [
                _vm._v(
                  "\n                        References\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v(
                  "\n                        A reference to the source from which the definition text is taken.\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dt", { staticClass: "offset-1 col-sm-2" }, [
                _vm._v(
                  "\n                        Comments\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v(
                  "\n                        Additional comments about the metadata item, such as a guide for use or notes about its origin.\n                    "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("\n            Specific fields\n        ")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Metadata type specific fields are listed below:\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-10 offset-1" }, [
            _c(
              "table",
              { staticClass: "table table-striped table-sm table-bordered" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n                            Metadata Type\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n                            Fields\n                        "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Data Element\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            Common fields only\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Data Element Concept\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            Common fields only\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Object Class\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            Common fields only\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Property\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            Common fields only\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Value Domain\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v("\n                            Common fields"),
                      _c("br"),
                      _vm._v(
                        "\n                            Permissible Values "
                      ),
                      _c("small", [_vm._v("(See below)")]),
                      _c("br"),
                      _vm._v(
                        "\n                            Supplementary Values "
                      ),
                      _c("small", [_vm._v("(See below)")]),
                      _c("br"),
                      _vm._v("\n                            Format"),
                      _c("br")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Data Type\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            Common fields only\n                        "
                      )
                    ])
                  ])
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("h3", [
          _vm._v(
            "\n            Entering Permissible and Supplementary Values\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", [
            _vm._v(
              "\n        Aristotle allows you to record the code and the meaning when recording possible values for a value domain.\n        Rather than have codes and meanings in a separate table, or spread over multiple rows in Excel, you can record these SDDF\n        using semi-colons and line breaks to separate values.\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              '\n        For example, if you are measuring employment data had a Value Domain with two codes "FT" meaning Full time and "PT" meaning Part time, these can be entered in the cell\n        for the "Permissible Value" Column like so:\n        '
            )
          ]),
          _c("div", { staticClass: "well" }, [
            _c("pre", [
              _vm._v(
                "            FT; Full-time employment\n            PT; Part-time employment\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "alert alert-info" }, [
            _vm._v(
              "Hint: When using Excel you can add a new line inside a cell by pressing "
            ),
            _c("kbd", [_vm._v("Alt")]),
            _vm._v("+"),
            _c("kbd", [_vm._v("Enter")]),
            _vm._v("!")
          ]),
          _vm._v(" "),
          _c("p")
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("\n            Field order\n        ")]),
        _vm._v(
          "\n\n        Mapping of fields in the dictionary to values in a metadata registry can be determined from header field definitions, so ordering is not required for import and structuring of information.\n        For consistency and readability, it is recommended fields are stored in in the following order:\n        "
        ),
        _c("div", { staticClass: "d-flex justify-content-center pt-4 pb-4" }, [
          _c("ol", [
            _c("li", [
              _vm._v("\n                    Data Element\n                ")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n                    Data Element Concept\n                "
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("\n                    Object Class\n                ")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("\n                    Property\n                ")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("\n                    Value Domain\n                ")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("\n                    Data Type\n                ")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("\n            Reusing records\n        ")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            If a metadata object needs to be reused throughout the dictionary, such as a common Object Class such as\n            “Business”, to prevent re-entry of content the name is used as a key for identifying duplicate information.\n        "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            During import a metadata object is only created when a name for an object is first encountered.\n            All subsequent uses of the name will refer to the original entry.\n        "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            For example, for the following table, if imported only one Object Class would be created – “Business”\n            with the definition “An organization or entity engaged in commercial activities”. All other records that refer to a business would link to this Object Class.\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-5" }, [
          _c("div", { staticClass: "col-12 d-flex justify-content-center" }, [
            _c("table", { staticClass: "excel-table-2007" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/excel-2007-header-left.gif"),
                        alt: "excel-type-header"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(
                      "\n                            Object Class\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(
                      "\n                            Object Class Definition\n                        "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "heading", attrs: { scope: "row" } },
                    [
                      _vm._v(
                        "\n                            1\n                        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                            Business\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                            An organization or entity engaged in commercial activities\n                        "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "heading", attrs: { scope: "row" } },
                    [
                      _vm._v(
                        "\n                            2\n                        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                            Business\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "heading", attrs: { scope: "row" } },
                    [
                      _vm._v(
                        "\n                            3\n                        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                            Business\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                            A company that performs services.\n                        "
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pt-4 pb-4" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h3", [
              _vm._v(
                "\n                    Rules for including fields\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10 offset-1" }, [
            _c(
              "table",
              { staticClass: "table table-striped table-sm table-bordered" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n                            Metadata Type\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n                            Rules\n                        "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Data Element\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            A Data Element should always be present.\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Data Element Concept\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "\n                                    If the “Data Element” is not present in the row, a Data Element Concept will be\n                                    created but will not be related to any Data Element.\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            '\n                                    If the “Data Element Concept” is empty, this is computed by concatenating the names of\n                                    the “Object Class” and “Property” in the same row, separated by a colon ":".\n                                '
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            '\n                                    If the "Data Element Concept name" is empty and the fields "Object Class" and "Property\n                                    name" are empty too, a Data Element Concept will not be created for that row.\n                                '
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Object Class\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "\n                                    If “Data Element Concept” field is not present in the row, an Object Class will be\n                                    created but will not be related to any Data Element Concept.\n                                "
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Property\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "\n                                    If “Data Element Concept” field is not present in the row, a Property will be\n                                    created but will not be related to any Data Element Concept.\n                                "
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Value Domain\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "\n                                    If “Data Element” field is not present in the row, a Value Domain will be created\n                                    but will not be related to any Data Element.\n                                "
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n                            Data Type\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "\n                                    If “Value Domain” field is not present in the row, a Data Type will be created\n                                    but will not be related to any Value Domain.\n                                "
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("h3", [
          _vm._v(
            "\n            Compatibility with Distributions and Data Set Specifications\n        "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            In order to add definitions to a Distribution or a Data Set Specification from the SDDF importer follow\n            these steps:\n        "
          )
        ]),
        _vm._v(" "),
        _c("ol", [
          _c("li", [
            _vm._v(
              '\n                Click on the "Import definitions from SDDF" button located in the Actions tab of the item page.\n                '
            ),
            _c("div", { staticClass: "text-center pt-4 pb-5" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/import_definitions_from.png"),
                  alt: "aristotle-actions"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              '\n                Add an extra column to your excel file named "Logical Path" if you are importing from a Distribution\n                or "Reference" if you are importing from a Data Set Specification.\n                '
            ),
            _c("div", { staticClass: "row pt-4 pb-5" }, [
              _c(
                "div",
                { staticClass: "col-12 d-flex justify-content-center" },
                [
                  _c("table", { staticClass: "excel-table-2007" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/excel-2007-header-left.gif"),
                              alt: "excel-type-header"
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                                    Reference\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                                    Object Class\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                                    Object Class Definition\n                                "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c(
                          "td",
                          { staticClass: "heading", attrs: { scope: "row" } },
                          [
                            _vm._v(
                              "\n                                    1\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                    Ref\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                    Business\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                    An organization or entity engaged in commercial activities\n                                "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "td",
                          { staticClass: "heading", attrs: { scope: "row" } },
                          [
                            _vm._v(
                              "\n                                    2\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                    Ref\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                    Person\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                    a human being, whether an adult or child\n                                "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "td",
                          { staticClass: "heading", attrs: { scope: "row" } },
                          [
                            _vm._v(
                              "\n                                    3\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                    Ref\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                    Age\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                    The length of time that a person has lived or a thing has existed\n                                "
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-10 offset-1" })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pt-4 pb-4" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h3", [
              _vm._v("\n                    Additional help\n                ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10 offset-1" }, [
            _c("p", [
              _vm._v(
                "\n                    If you have questions about the Simple Data Dictionary Format,\n                    need help producing a valid data dictionary or have recommendations or suggestions for how we can improve the SDDF tools\n                    "
              ),
              _c(
                "a",
                { attrs: { href: "https://community.aristotlemetadata.com/" } },
                [
                  _vm._v(
                    "sign up to our\n                    metadata community and let us know your thoughts"
                  )
                ]
              ),
              _vm._v(".\n                ")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }