var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("custom-header"),
      _vm._v(" "),
      _c(
        "main",
        [
          _vm.browser == "ie"
            ? _c(
                "div",
                { staticClass: "browser-message alert alert-warning" },
                [
                  _vm._v(
                    "\n            This site does not support Internet Explorer, some functionality may not work as expected\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("router-view")
        ],
        1
      ),
      _vm._v(" "),
      _c("custom-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }