var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "title" }, [
      _c(
        "strong",
        {
          staticClass: "ml-20",
          attrs: { "data-aristotle-concept-id": _vm.id }
        },
        [_vm._v(_vm._s(_vm.name))]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-block" },
      _vm._l(_vm.options, function(o) {
        return _c("div", { key: o.id }, [
          _c(
            "div",
            {
              ref: "content",
              refInFor: true,
              staticClass: "form-check",
              attrs: { "data-tippy-content": o.definition }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checkedOptions,
                    expression: "checkedOptions"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox", id: o.id },
                domProps: {
                  value: o.name,
                  checked: Array.isArray(_vm.checkedOptions)
                    ? _vm._i(_vm.checkedOptions, o.name) > -1
                    : _vm.checkedOptions
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.checkedOptions,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = o.name,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.checkedOptions = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checkedOptions = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checkedOptions = $$c
                      }
                    },
                    _vm.updateCheckedOptions
                  ]
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "form-check-label", attrs: { for: o.id } },
                [_vm._v(_vm._s(o.name))]
              )
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }