var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("\n        How the data was created\n    ")
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("metadata-display", {
        attrs: { selected: _vm.selected, dss: _vm.dss, tooltips: "" }
      }),
      _vm._v(" "),
      _vm._m(1)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container before" }, [
      _c("p", [
        _vm._v(
          "\n            The diagram below shows information about how the data above was collected and transformed before it was published. A record of how data was collected and how it has changed is called "
        ),
        _c(
          "a",
          { attrs: { href: "https://en.wikipedia.org/wiki/Data_lineage" } },
          [_c("dfn", [_vm._v("data lineage")])]
        ),
        _vm._v(
          " and is an important part of reporting as it tells people where data has come from. "
        ),
        _c("dfn", [_vm._v("Metadata")]),
        _vm._v(" is an important part of recording "),
        _c("dfn", [_vm._v("data lineage")]),
        _vm._v(
          " by making sure that transformations and fields are recorded in a way is managed and accessible.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container after" }, [
      _c("p", [
        _vm._v(
          "This diagram is updated dynamically from the metadata to show how the selected data was collected and transformed before being displayed on the above graph. Hover over any of the boxes for more information about where this data came from and how it changed over time."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }